//iPad basic
@media only screen and (max-width: 1850px) {

	img#logo {
		// margin-left: 0;
	}

	nav#navholder>.wrapper {
		float: right;
	}

}

@media only screen and (max-width: 1280px) {

	ul.nav {
		width: 80%;
		float: right;
	}

	img#logo {
		// margin-top: 10px;
		height: 100px;
	}

}

// Mobile Version

@media only screen and (max-width: 990px) {

	// Body

	body {
		min-width: 0;
		overflow-x: hidden;
	}

	// Global

	img {
		max-width: 100%;
		height: auto;
	}

	table {
		max-width: 100%;
	}

	//Responsive tables
	table.tableborder {
		width: 100%;
		overflow: scroll;
		white-space: nowrap;
		table-layout: fixed;
		position: relative;

		&:before {
			content: "";
			width: 30px;
			height: 100%;
			position: absolute;
			top: 0;
			right: -1px;
			background: -moz-linear-gradient(left, rgba(255, 255, 255, 0) 0%, rgba(0, 0, 0, 0.2) 100%);
			background: -webkit-linear-gradient(left, rgba(255, 255, 255, 0) 0%, rgba(0, 0, 0, 0.2) 100%);
			background: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, rgba(0, 0, 0, 0.2) 100%);
			filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#00ffffff', endColorstr='#33000000', GradientType=1);
		}

		tbody {
			width: 100%;
			float: left;
			white-space: normal;
			overflow-x: scroll;
		}
	}

	// Wrappers
	.wrapper {
		width: 100%;
	}

	.wrapper-inside {
		width: 100%;
	}

	// Calendar

	#calendar {
		.fc-header td {
			display: block;
			width: 100%;
		}

		h2 {}

		.fc-button-content {
			font-family: Arial;
		}

		.fc-header-right {
			text-align: left;
		}

		.fc-header-center {
			text-align: left;
		}
	}

	// Forms

	form div input[type="text"],
	form div textarea,
	form span textarea,
	form div input[type="textarea"],
	form div input[type="password"] {
		width: 200px;
	}

	form div span.button {
		padding-left: 0;
	}

	//Search Form
	#FS {
		display: none;
	}

	.translate {
		display: none;
	}

	.twitter-header {
		display: none;
	}

	.parentpay-ic {
		display: none;
	}

	// Header
	header {
		height: auto;
		overflow: hidden;
		background: rgba(255, 255, 255, 0.6);

		.wrapper {
			padding: 0;
		}
	}

	img#logo {
		margin: 60px auto 20px;
		float: none;
		display: block;
		max-width: 85%
	}

	//Slider

	body.body_3060 #slider {
		height: 1000px !important;

		ul {
			bottom: 0;

			li {
				top: 0 !important;
			}
		}
	}

	#slider {}

	#welcome {
		height: auto;
		background: rgba(#FD834C, 0.9);
		overflow: hidden;
		margin: 0;
		min-height: inherit;

		h2.strapline {
			text-align: center;
			position: inherit;
			top: 0;
			margin: 20px auto;
		}
	}

	.welcome {
		width: 80%;
		padding: 5%;
		margin: 5%;
		position: inherit;
		left: 0;
		right: 0;
		top: 0;
		background: #fff;
		height: auto;

		.vision-values {
			position: inherit;
			left: 0;
			right: 0;
			top: 0;
			text-align: center;

			&:before {
				display: none;
			}

			h2 {
				text-align: center;
				margin: 20px auto;
			}
		}

		h2 {
			margin: 20px auto;
			text-align: center;
		}

		p {
			margin: 12px auto;
			text-align: center;
		}
	}

	.home-quotes {
		display: none;
	}


	// Mainholder
	#mainholder {
		width: 100%;
		padding: 0;
	}

	#diary-dates {
		height: auto;
		margin: 0;
		background: #F36D21;
	}

	#SideHeadingDiary {
		h2 {
			margin: 40px auto;
		}

		ul.SideList li {
			float: none !important;
			display: block;
			width: 80%;
			margin: 20px auto;
		}

		a.more {
			display: block;
			margin: 10px auto;
		}
	}

	// Contents
	.Content1Column,
	.Content2Column,
	.Content3Column {
		width: 80%;
		padding: 5%;
		margin: 5%;
	}

	// Col Left and Right
	.ColumnLeft,
	.ColumnRight {
		width: 80%;
		padding: 5%;
		margin: 5%;
	}

	//Home page alert
	.modal-home {
		.message-modal {
			width: 90%;
			margin: 0 5%;
			left: 0;
			top: 20%;
			padding: 0;

			.modal-content {
				.modal-text {
					padding: 3%;
				}

				a.close-modal {
					position: absolute;
					top: 3%;
					right: 3%;
					background: #fff;
					padding: 3px;
					border-radius: 0px 0px 0px 7px;
				}
			}
		}
	}

	#social-media {
		display: none;
	}

	// Footer
	footer {
		height: auto;
		background: rgba(#FD834C, 0.9);

		.wrapper {
			width: 90%;
			padding: 5%;

			p {
				text-align: center;
				margin: 10px auto;
				display: block;
				position: inherit;
				left: 0;
				right: 0;
				top: 0;
				bottom: 0;

				&:before {
					display: none;
				}
			}

			a {
				word-break: break-all;
			}
		}
	}

	#map {
		display: none;
	}

	#footer-logo {
		position: inherit;
		left: 0;
		top: 0;
		margin: auto;
		display: block;
	}

	#awards {
		display: none;
	}

	.sub-footer {
		height: auto;
		line-height: normal;
		padding: 5px 0;

		p {
			text-align: center;
			margin: 5px auto;
			float: none;
		}

		#credit {
			float: none;
		}
	}
}


@media only screen and (max-width: 500px) {

	#slider .trust-tab {
		display: none;
	}


}