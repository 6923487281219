@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

// -----------------------------------//
// GLOBAL STYLES
// -----------------------------------//

// BODY
body {
	color: #111;
	font-size: 18px;
	font-family: 'Poppins', sans-serif;
	text-align: center;
	line-height: 1.528571429;
	padding: 0px;
	margin: 0;
}

// body                                 { background: url(/i/design/design.jpg) center top no-repeat; opacity: .5; }

// LINKS
body a {
	color: #f5853f;
	text-decoration: none;
	transition: 1s;
}

body a:hover,
body a:focus {
	text-decoration: underline;
}

// LINKED IMAGES
a img,
img a,
img,
p a img {
	border: 0;
	border-bottom: 0;
	text-decoration: none;
	vertical-align: middle;
}

a img:hover {
	opacity: 0.8
}

// HEADINGS
h1,
h2,
h3,
h4,
h5,
h6 {
	font-weight: 500;
}

h1 {
	font-size: 42px;
	margin: 0 0 20px 0;
	color: #FD834C;
	line-height: 1.2;
}

h2 {
	font-size: 30px;
	margin: 25px 0 20px 0;
	color: #916dd5;
	line-height: 1.3;
}

h3 {
	font-size: 15px;
	margin: 25px 0 10px 0;
	padding: 5px 10px;
	background: #f5853f;
	color: #fff;
}

h4 {
	@extend h3;
	background-position: 10px center;
	background-repeat: no-repeat;
	padding-left: 30px;
}

h4.open {
	background-image: url(/i/design/expanding-faq/minus-white.png);
}

h4.closed {
	background-image: url(/i/design/expanding-faq/plus-white.png);
}

h5 {
	font-size: 12px;
}

h6,
small {
	font-size: 10px;
}

h1+h2 {
	margin-top: 0;
	border: 0;
	padding: 0;
}

h1+p,
h2+p,
h3+p {
	margin-top: 0;
}

// PARAGRAPHS AND LIST ITEMS
p {
	margin: 12px 0;
	padding: 0;
}

li {
	margin: 2px 0;
	padding: 0 0 0 23px;
	background: url(/i/icons/bullet_black.png) no-repeat top 6px left 3px;
}

// FORMS
form {
	margin: 0;
	padding: 0;
	@extend .clearfix;
}

fieldset {
	border: 1px solid #c0c0c0;
	margin: 0 2px;
	padding: 0.35em 0.625em 0.75em;
}

legend {
	border: 0;
	padding: 0;
	white-space: normal;
	*margin-left: -7px;
}

button,
html input[type="button"],
input[type="reset"],
input[type="submit"] {
	-webkit-appearance: button;
	cursor: pointer;
	*overflow: visible;
	/* 4 */
}

button[disabled],
html input[disabled] {
	cursor: default;
}

input[type="checkbox"],
input[type="radio"] {
	box-sizing: border-box;
	padding: 0;
	*height: 13px;
	*width: 13px;
}

input[type="search"] {
	-webkit-appearance: textfield;
	box-sizing: content-box;
}

input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-decoration {
	-webkit-appearance: none;
}

button::-moz-focus-inner,
input::-moz-focus-inner {
	border: 0;
	padding: 0;
}

textarea {
	resize: vertical;
	overflow: auto;
	vertical-align: top;
}

label {
	float: left;
	margin: 10px 0;
	width: 90px;
	display: inline;
}

form .formfield {
	margin: 10px;
	float: left;
	display: inline;
	width: 200px;
	border: 1px solid #CCC;
	padding: 2px;
}

form div {
	margin: 15px 0;
	display: block;
	clear: left;
	vertical-align: top;
}

form div input[type="text"],
form div textarea,
form span textarea,
form div input[type="textarea"],
form div input[type="password"] {
	border: 1px solid #CCC;
	padding: 4px;
	width: 300px;
	margin: 0;
	background-color: #EFEFEF;
}

input[type="text"]:focus,
form div textarea:focus,
form span textarea:focus,
form div input[type="textarea"]:focus,
form div input[type="password"]:focus {
	border-color: #000;
}

form div span.label label,
form div span.label,
span.spacer,
span.multiple span {
	width: 180px;
	float: left;
	margin: 0 0 0 0;
}

form div span.multiple {
	float: left;
}

form div span.button {
	padding-left: 180px;
	background: transparent;
	border: 0;
}

form div span.button input {
	background: #666;
	color: #FFF;
	margin: 0;
	padding: 8px 10px;
	font-weight: bold;
	border: 0;
}

form div span.button input:hover {
	background: #888;
}

form div.clear {
	clear: both;
	padding-top: 5px;
}

// -----------------------------------//
// IE WARNING
// -----------------------------------//

#ie-warning {
	width: 100%;
	background: #b20000;
	color: #fff;
	overflow: hidden;
	padding: 0 0 10px 0;
	transition: 1s;
	display: block
}

#ie-warning p {
	margin: 5px auto;
}

#ie-warning a {
	color: #fff;
	text-decoration: underline;
	cursor: pointer;
}


// -----------------------------------//
// WRAPPER
// -----------------------------------//

body.body_3060 .wrapper-inside {
	width: 100%;
}

.wrapper-inside {
	margin: 0 auto;
	width: 990px;
	height: 100%;
	@extend .clearfix;
}

#wrapper {
	margin: 0 auto;
	text-align: center;
}

.wrapper {
	width: 990px;
	margin: 0 auto;
	height: 100%;
	@extend .clearfix;
}

// -----------------------------------//
// HEADER
// -----------------------------------//

header {
	height: 207px;
	background: url(/i/design/header-bg.png) repeat-x top center;
}

header>.wrapper {
	padding: 0;
	position: relative;
	z-index: 60;
}

header h1 {
	margin: 0;
	background: none;
}

#logo-index {
	margin: 0;
	padding: 0;
}

img#logo {
	float: left;
	margin: 35px 0 0 40px;
}

.twitter-header {
	position: absolute;
	top: 19px;
	right: 131px;
	background: url(/i/design/twitter-header.png) no-repeat;
	height: 34px;
	width: 36px;
}

.parentpay-ic {
	position: absolute;
	top: 19px;
	right: 66px;
	background: url(/i/design/parentpay-ic.png) no-repeat;
	height: 34px;
	width: 36px;
}

// -----------------------------------//
// SEARCH FORM
// -----------------------------------//

#FS {
	position: absolute;
	right: 263px;
	top: 18px;
	z-index: 9999;
	font-size: 16px;
}

#FS input {
	outline: none;
	color: #fff;
}

#FS input[type=search] {
	-webkit-appearance: textfield;
	-webkit-box-sizing: content-box;
	font-family: inherit;
	font-size: 100%;
}

#FS input::-webkit-search-decoration,
#FS input::-webkit-search-cancel-button {
	display: none;
}

#FS input[type=search] {
	color: transparent;
	cursor: pointer;
	background: url(/i/design/search-ic.png) no-repeat 0 center;
	padding: 0px;
	width: 32px;
	height: 36px;
	transition: all .5s;
}

#FS input[type=search]:focus {
	width: 200px;
	background: #000;
	padding: 5px 0 5px 12px;
	color: #fff;
	cursor: auto;
	font-weight: 400;
}

#FS input[type=search]:focus:-moz-placeholder {
	color: #fff;
	font-weight: 300;
	-webkit-transition: .3s ease-out;
	transition: .3s ease-out;
}

#FS input[type=search]:focus::-webkit-input-placeholder {
	color: #fff;
	font-weight: 300;
	-webkit-transition: .3s ease-out;
	transition: .3s ease-out;
}

#FS input:-moz-placeholder {
	color: transparent;
	-webkit-transition: .3s ease-out;
	transition: .3s ease-out;
}

#FS input::-webkit-input-placeholder {
	color: transparent;
	-webkit-transition: .3s ease-out;
	transition: .3s ease-out;
}


// -----------------------------------//
// SEARCH RESULTS
// -----------------------------------//

$search-highlight-background-color: #FD834C;
$search-highlight-color: #fff;
$search-highlight-font-weight: bold;
$search-highlight-font-size: 15px;
$search-odd-tr-background: #ececec;

.search-subject-box {
	display: inline-block;
	background: #f1f1f1;
	padding: 5px 15px;
	font-size: 16px;
	border-radius: 4px;
	border: 1px solid #ccc;
	text-transform: uppercase;
}

.search-numbers {
	text-transform: uppercase;
	font-weight: bold;
	font-size: 12px;

	span {
		background: #f1f1f1;
		padding: 3px 8px;
		margin: 0 3px;
		border-radius: 2px;
		border: 1px solid #ccc;
	}
}

// -----------------------------------//
// GOOGLE TRANSLATE WIDGET
// -----------------------------------//

.goog-te-gadget .goog-te-combo {
	margin: 0px !important;
	width: 35px;
	height: 35px;
}

#google_translate_element {
	opacity: 0;
}

.translate {
	position: absolute;
	right: 196px;
	top: 18px;
	overflow: hidden;
	background: url(/i/design/translate-ic.png) no-repeat;
	width: 35px;
	height: 35px;
}

// -----------------------------------//
// MAIN HOLDER
// -----------------------------------//

body.body_3060 #mainholder {
	width: 100%;
	padding: 0;
	background: none
}

#mainholder {
	width: 100%;
	background: #fff;
	margin: 0px auto;
	padding: 30px 0 30px 0;
	clear: both;
	text-align: left;
	@extend .clearfix;
}


// -----------------------------------//
// WELCOME
// -----------------------------------//


#welcome {
	min-height: 761px;
	padding: 0 0 30px 0;
	background: url(/i/design/welcome-bg.png) repeat-x top center;
	background-size: cover;
	margin: 0 0 -105px 0;
	position: relative;

	.wrapper {
		position: relative;
	}

	h2.strapline {
		color: #000;
		font-size: 36px;
		font-weight: 700;
		margin: 0;
		position: absolute;
		left: 0;
		top: 170px;
	}
}

.welcome {
	background: url(/i/design/welcome-blob.png) no-repeat;
	height: 648px;
	width: 1064px;
	position: absolute;
	top: 250px;
	left: -60px;
	margin: auto;
	z-index: 9;
	overflow: hidden;

	.vision-values {
		position: absolute;
		left: 680px;
		top: 200px;

		&:before {
			content: '';
			position: absolute;
			left: -130px;
			top: 0;
			height: 101px;
			width: 100px;
			background: url(/i/design/values-diamond.png) no-repeat;
		}

		h1 {
			margin: 0;
			font-size: 36px;
			color: #FBB900;
			font-weight: 700;

			&:first-letter {
				color: #f5853f;
			}
		}

		h2 {
			margin: 0;
			text-align: left;
			line-height: 1;
		}
	}

	h2 {
		font-size: 48px;
		color: #414142;
		font-weight: 700;
		margin: 100px 0 30px 150px;
	}

	p {
		color: #0d1e41;
		font-size: 18px;
		margin-left: 150px;
		max-width: 400px;
		line-height: 1.367;

		&:first-of-type {
			font-size: 24px;
			font-weight: 600;
		}

		&:last-of-type {
			text-align: right;
		}
	}

	a {
		color: #f5853f;
		font-weight: 600;
		text-transform: uppercase;

		&:hover {
			text-decoration: none;
			letter-spacing: 0.1em;
		}
	}

}

.home-quotes {
	position: absolute;
	right: 0;
	top: 150px;
	height: 267px;
	width: 615px;
	background: url(/i/design/home-quotes-bg.png) no-repeat;
	z-index: 99;


	ul#bxslider {
		li {
			padding: 0;
			background: none;
			margin: 80px auto 0;
			text-align: center;
			color: #fff;
			font-size: 24px;
			text-shadow: 1.696px 1.06px 1px rgba(4, 46, 110, 0.66);
		}
	}

}

.bx-wrapper {
	margin: auto;
}

.bx-controls {
	position: absolute;
	bottom: 64px;
	text-align: right;
	right: 90px;
	margin: auto;
}

.bx-pager-item {
	background: #f5853f;
	display: inline-block;
	height: 15px;
	width: 15px;
	border-radius: 50%;
	margin: 0 5px;
	overflow: hidden;

	a {
		color: transparent;
	}

	.active {
		background: #fff;
		height: 15px;
		width: 15px;
		display: block;
	}
}

.bx-viewport {
	height: 270px !important;
}

// -----------------------------------//
// MISCELLANEOUS
// -----------------------------------//

#social-media {
	width: 100%;
	height: 967px;
	background: url(/i/design/social-section.png) repeat-x bottom center;
	background-size: cover;
	overflow: hidden;
	position: relative;
	z-index: 1;

	.wrapper {
		position: relative;
	}
}


.social-column {
	display: inline-block;
	width: 300px;
	margin: auto;
	position: absolute;
	left: 0;
	top: 260px;

	h2 {
		margin: 0 auto 40px;
		color: #414142;
		font-size: 48px;
		font-weight: 700;
	}

	a.more {
		color: #15202b;
		font-weight: 900;
		font-size: 18px;
		text-transform: uppercase;
		margin: 30px auto 0;
		display: block;

		&:hover {
			text-decoration: none;
			letter-spacing: 0.1em;
		}
	}
}

.social-column-2 {
	display: inline-block;
	width: 300px;
	margin: auto;
	position: absolute;
	left: 0;
	right: 0;
	top: 260px;

	h2 {
		margin: 0 auto 40px;
		color: #414142;
		font-size: 48px;
		font-weight: 700;
	}

	a.more {
		color: #15202b;
		font-weight: 900;
		font-size: 18px;
		text-transform: uppercase;
		margin: 30px auto 0;
		display: block;

		&:hover {
			text-decoration: none;
			letter-spacing: 0.1em;
		}
	}
}

.social-column-3 {
	display: inline-block;
	width: 300px;
	margin: auto;
	position: absolute;
	right: 0;
	top: 260px;

	h2 {
		margin: 0 auto 40px;
		color: #414142;
		font-size: 48px;
		font-weight: 700;
	}

	a.more {
		color: #15202b;
		font-weight: 900;
		font-size: 18px;
		text-transform: uppercase;
		margin: 30px auto 0;
		display: block;

		&:hover {
			text-decoration: none;
			letter-spacing: 0.1em;
		}
	}
}


// -----------------------------------//
// CONTENT COLUMNS
// -----------------------------------//

//Content 1
.Content1Column {
	width: 930px;
	margin: 0;
	padding: 30px;
	float: left;
	min-height: 350px;
	text-align: left;

	img {
		max-width: 100%;
		height: auto;
	}

	h1 {
		text-transform: uppercase;
	}

	h2 {
		font-weight: 700;
	}
}

//Content 2
.Content2Column {
	width: 650px;
	margin: 0;
	padding: 30px;
	float: left;
	min-height: 350px;
	text-align: left;

	img {
		max-width: 100%;
		height: auto;
	}

	h1 {
		text-transform: uppercase;
	}

	h2 {
		font-weight: 700;
	}

	tr,
	td {
		border: 1px solid #000 !important;
	}

	.tablenoborderpadded {
		border: 0 !important;

		tr,
		td {
			border: 0px !important;
		}
	}
}

//Content 3
.Content3Column {
	width: 430px;
	margin: 0;
	padding: 0;
	float: left;
	min-height: 350px;
	text-align: left;

	img {
		max-width: 100%;
		height: auto;
	}
}

.HomeAlert .Content1Column {
	float: none;
	margin: 0 auto;
}

// -----------------------------------//
// COLUMN LEFT & COLUMN RIGHT
// -----------------------------------//

.ColumnLeft {
	width: 250px;
	margin: 0;
	padding: 0;
	min-height: 250px;
	text-align: left;
	float: left;
}

.ColumnRight {
	width: 250px;
	margin: 0;
	padding: 0;
	min-height: 250px;
	text-align: left;
	float: right;
}

.ColumnLeft ul,
.ColumnRight ul {
	margin: 0;
	padding: 0;
	list-style: none;
}

.ColumnLeft nav ul a:link,
.ColumnRight nav ul a:visited,
.ColumnRight nav ul a:link,
.ColumnRight nav ul a:visited {
	display: block;
}

.ColumnLeft li {
	border-bottom: 1px solid #EEE;
}

// Hack to fix IE/Win's broken rendering of block-level anchors in lists
html>body .ColumnLeft li {
	border-bottom: none;
}

// Fix for browsers that don't need the hack

// -----------------------------------//
// SIDE COLUMN > LIST SECTIONS
// -----------------------------------//

// Container
.SideHeading {
	margin: 30px 0 0 0;
	padding: 0;
	text-align: center;
}

.SideHeading:first-child {
	margin-top: 0;
}

// Heading
.SideHeading h2 {
	display: block;
	text-align: center;
	font-size: 24px;
	padding: 0;
	margin: 0;
}

// List
ul.SideList {
	margin: 0 0 0 0;
	padding: 0;
	border: 0;
	list-style: none;
	text-align: left;
}

ul.SideList li {
	margin: 0 0 0 0;
	padding: 0;
	background: none;
}

ul.SideList li a {
	margin: 0;
	padding: 5px 10px;
	color: #333;
	transition: 1s;
	font-weight: normal;
	display: block;
}

ul.SideList li a:hover {
	color: #FFF;
	transition: 1s;
	text-decoration: none;
}

ul.SideList li a.child {
	background: url(/i/design/svg/ic-related-pages.svg) left 20px center no-repeat;
	padding-left: 40px !important;

	&:hover {
		background-color: #999;
	}
}


// Date and Title
ul.SideList li p {
	margin: 0;
	padding: 0;
}

ul.SideList p.date {
	float: left;
	text-align: left;
	color: #fff;
	text-transform: uppercase;
	font-size: 30px;
	line-height: 130%;
	font-weight: 700;
	background: url(/i/design/date-bg.png) no-repeat top left;
	width: 28%;
	height: 100%;
	padding: 0 0 0 15px;

	span {
		display: block;
		font-weight: bold;
		font-size: 60px;
		margin: 20px 0 0 0;
		color: rgba(255, 255, 255, 0.7);
		letter-spacing: -0.05em;
	}

	em {
		font-style: inherit;
		padding-left: 30px;
	}
}

ul.SideList p.title {
	float: right;
	width: 65%;
	font-size: 18px;
	color: #fff;
	font-weight: 300;
	margin: 15px 0 0 0;

	// Truncate
	span {
		overflow: hidden;
		display: -webkit-box;
		-webkit-line-clamp: 2;
		-webkit-box-orient: vertical;
	}
}

// More links
.SideHeading a.more {
	display: inline-block;
	font-size: 15px;
	padding: 0px;
	transition: 1s;

	&:hover {
		transition: 1s;
		text-decoration: none;
		letter-spacing: 0.1em;
	}
}

// -----------------------------------//
// SCHOOL FEEDS
// -----------------------------------//

#diary-dates {
	width: 100%;
	height: 967px;
	overflow: hidden;
	position: relative;
	background: url(/i/design/diary-bg.png) no-repeat center top;
	background-size: cover;
	margin: 0 0 -105px 0;

	.wrapper {
		overflow: hidden;
	}

}

//Diary
#SideHeadingDiary {

	h2 {
		margin: 220px auto 60px;
		color: #fff;
		font-size: 48px;
		font-weight: 700;
		text-shadow: 0px 2px 0px rgba(15, 38, 69, 0.47);
	}

	ul.SideList {
		li {
			float: left;
			width: 45%;
			height: 105px;
			margin-bottom: 15px;

			&:nth-of-type(even) {
				float: right;
			}

			a {
				padding: 0;
				height: 105px;
				border-radius: 20px;

				&:hover {
					background-color: #F7A885;
				}
			}
		}
	}

	a.more {
		color: #fff;
		font-size: 18px;
		font-weight: 700;
		text-transform: uppercase;
		margin: 50px 30px 0 30px;
	}
}

//News
#SideHeadingNews {}

//Related pages
#SideHeadingPages {
	overflow: hidden;
	background: #FD834C;

	h2 {
		max-width: 80%;
		margin: 20px auto;
		color: #fff;
		font-size: 30px;
	}

	ul.SideList {
		padding: 20px 0;

		li {
			a {
				padding: 7px 20px;
				font-size: 16px;
				color: #fff;

				&:hover {
					background-color: #fff;
					color: #FD834C;
				}
			}
		}
	}

}


// -----------------------------------//
// HOME PAGE ALERT
// -----------------------------------//

//Modal
.modal-home {
	background: rgba(0, 0, 0, .4);
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 8000;

	.message-modal {
		position: absolute;
		top: calc(50% - 300px);
		left: calc(50% - 465px - 40px - 10px);
		width: 930px;
		height: auto;
		margin: 0 30px;
		padding: 20px;
		background: rgba(255, 255, 255, .9);

		.modal-content {
			background: rgba(255, 255, 255, .9);
			background-position: -120px 50px;
			box-shadow: inset 0 0 0 1px #fff;
			display: inline-block;
			width: 100%;
			height: 100%;
			text-align: center;

			.modal-text {
				display: block;
				padding: 45px;

				p {
					margin: 0;
				}
			}

			a.close-modal {
				position: absolute;
				top: 31px;
				right: 31px;
			}
		}
	}
}

.hide-modal {
	display: none !important;
}


// -----------------------------------//
// AWARDS
// -----------------------------------//

#awards {
	width: 100%;
	overflow: hidden;
	background: #fff;
	padding: 13px 0;

	.CMSawards {
		overflow: hidden;
		width: 100%;
		height: 100%;
		margin: 0 auto !important;
		background: none !important;

		ul {
			margin: 0;

			li {
				background: none;
				padding: 0 5px;
				margin: 0 10px;
				display: inline-block;

				img {
					max-height: 70px;
					transform: none !important;
				}
			}
		}
	}
}


// -----------------------------------//
// FOOTER
// -----------------------------------//

#map {
	position: absolute;
	top: 0;
	margin: auto;
	width: 100%;
	height: 100%;
	z-index: -1;
}

footer {
	clear: both;
	height: 450px;
	position: relative;
	background: url(/i/design/footer-bg.png) repeat-x bottom center;
}

footer>.wrapper {
	color: #000;
	text-align: left;
	position: relative;
}

footer>.wrapper p {
	margin: 0;
}

footer>.wrapper a {}

#footer-logo {
	position: absolute;
	bottom: 45px;
	left: 0;
}

p.address {
	position: absolute;
	bottom: 50px;
	left: 340px;
	color: #fff;
	font-size: 18px;
	font-weight: 700;
	line-height: 1.167;

	&:before {
		content: '';
		position: absolute;
		left: -40px;
		top: 14px;
		background: url(/i/design/location-ic.png) no-repeat;
		height: 26px;
		width: 21px;
	}
}

p.contact {
	position: absolute;
	bottom: 85px;
	left: 620px;
	color: #fff;
	font-size: 18px;
	font-weight: 700;

	&:before {
		content: '';
		position: absolute;
		left: -40px;
		top: 0px;
		background: url(/i/design/contact-ic.png) no-repeat;
		height: 59px;
		width: 19px;
	}
}

p.email-us {
	position: absolute;
	bottom: 50px;
	left: 620px;
	color: #fff;
	font-size: 18px;
	font-weight: 700;

	a {
		color: #fff;
	}
}

// -----------------------------------//
// SUB-FOOTER
// -----------------------------------//

.sub-footer {
	height: 35px;
	width: 100%;
	line-height: 35px;
	background: #15202b;
	overflow: hidden;

	p {
		font-size: 13px;
		color: #fff;
		float: left;
		margin: 0;
	}

	a {
		color: #f5853f;
	}

	#credit {
		float: right;
	}
}

// -----------------------------------//
// CMS CONTENT: TABLES
// -----------------------------------//

$table-highlight-color: darkblue;
$table-highlight-background-color: lightblue;
$table-highlight-font-weight: bold;
$table-border-color: #000;

// -----------------------------------//
// CMS CONTENT: BLOCKQUOTES
// -----------------------------------//

blockquote p {
	font-family: 'Poppins', sans-serif;
	text-align: center;
}

$blockquote-background-color: #EFEFEF;
$blockquote-border-color: #EBEBEB;
$blockquote-color: #000;
$blockquote-speech-mark-color: #333;
$blockquote-font-size: 1em;

// -----------------------------------//
// CALENDAR TABLE BACKGROUND
// -----------------------------------//

.fc-content {
	background: none;
}


// -----------------------------------//
// BROWSER DETECTION
// -----------------------------------//

#browserblast {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 40px;
	border-bottom: 1px solid #D4C790;
	font-family: sans-serif;
	font-size: 12px;
	font-weight: bold;
	line-height: 40px;
	color: #6d6647;
	text-align: center;
	background: #FAF4AF;
}

#browserblast a {
	color: green;
	text-decoration: underline;
}

.unsupported-browser {
	margin-top: 41px;
}

// -----------------------------------//
// FAQ SCRIPT
// -----------------------------------//

// h3.faq-open { background: #EFEFEF url(/i/design/expanding-faq/minus.png) right 12px center no-repeat; }
// h3.faq-closed { background: #EFEFEF url(/i/design/expanding-faq/plus.png) right 12px center no-repeat; }

// -----------------------------------//
// SWITCH MOBILE
// -----------------------------------//

#switch-mobile {
	width: 100%;
	margin: 0;
	padding: 20px 0;
	background: #EFEFEF;
	border-top: 1px solid #EBEBEB;
	border-bottom: 1px solid #EBEBEB;
	text-align: center;
	color: #777;
	font-family: sans-serif;
	font-size: 12px;
	display: block;
	clear: both;
}

#switch-mobile a {
	text-decoration: underline;
}