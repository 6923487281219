// -----------------------------------//
// ELEMENTS COLOURS
// -----------------------------------//

//Grid and List view styles
$list-grid-tooltip-bg: #000;

//News list inside page colours
$little-date-line: #FD834C;
$news-list-bg: #f7f7f7;

//Subscribe
$subscribe-link-colour: #fff;
$subscribe-bg-colour: #FD834C;

//Diary list inside page colours
$diary-date-bg: #FD834C;
$diary-list-bg: #f7f7f7;
$diary-even-row-bg: #ececec;
$add-to-calendar-bg: #FD834C;

//Auto List
$auto-list-bg: #f7f7f7;
$auto-list-border-colour: #dedede;

//Gallery
$gallery-bg: #FD834C;

// -----------------------------------//
// PHOTO BUTTONS
// -----------------------------------//

//Photo box text colours (DARK background)

// Date
$box-light-date: #FD834C;
//Title
$box-light-title: #fff;

//Photo box text colours (LIGHT background)

// Date
$box-dark-date: #163a64;
//Title
$box-dark-title: #222;


// -----------------------------------//
// GRID AND LIST BUTTONS
// -----------------------------------//

h2.ListingHead {
	background: #FD834C;
}


// -----------------------------------//
// NEWS INSIDE PAGE LISTING
// -----------------------------------//

//Subscribe button
h3.news-subscribe {
	background-image: url(/i/icons/rss-white.png);
	background-repeat: no-repeat;
	border-radius: 5px;
	background-position: 10px;
	padding-left: 35px;
	background-size: 15px;
	background-color: $subscribe-bg-colour;
	clear: both;

	a {
		color: $subscribe-link-colour;
	}

	@media only screen and (max-width: 770px) {
		font-size: 15px;
	}
}


// -----------------------------------//
// DIARY DATES INSIDE PAGE LISTING
// -----------------------------------//



h3.adding-to-calendar {
	border-radius: 5px;
	color: #fff;
	background: $add-to-calendar-bg;
}

ul#DiaryListing {
	margin: 0 0 0 0;
	padding: 0;

	li {
		display: block;
		background: none;
		margin: 0 0 10px 0;
		padding: 15px 20px;
		background: $diary-list-bg;
		position: relative;
		border-bottom: 1px solid #ccc;

		//STRIPED ROW
		&:nth-of-type(even) {
			background: $diary-even-row-bg
		}

		// STYLES FOR MIXED DATE
		.mix-date {
			width: 130px;
			height: 60px;
			background: $diary-date-bg;
			color: #fff;
			display: inline-block;
			text-align: center;
			position: relative;
			text-transform: uppercase;
			font-size: 11px;
			margin: 0 20px 0 0;
			float: left;

			p {
				width: 50%;
				height: 100%;
				float: left;
				margin: 0;

				&:last-of-type {
					background: rgba(0, 0, 0, .4);
				}
			}

			span {
				display: block;
				font-size: 25px;
			}

			span.to {
				width: 20px;
				height: 20px;
				border-radius: 50%;
				position: absolute;
				top: 50%;
				left: 50%;
				text-align: center;
				line-height: 20px;
				margin-left: -10px;
				margin-top: -15px;
				font-size: 9px;
				background: $diary-date-bg;
				color: #fff;
			}
		}

		// STYLES FOR SINGLE DATE
		.diary-date {
			width: 130px;
			height: 60px;
			background: $diary-date-bg;
			color: #fff;
			display: inline-block;
			text-align: center;
			position: relative;
			text-transform: uppercase;
			font-size: 11px;
			margin: 0 20px 0 0;
			float: left;

			span {
				display: block;
				font-size: 25px;
			}
		}

		//HOUR
		.hour {
			display: inline-block;
			position: absolute;
			top: 10px;
			left: 170px;
			font-size: 12px;
			background: url(/i/icons/ic-hour.png) no-repeat 0 2px;
			padding: 0 0 0 18px;
			background-size: contain;
			background-size: 13px;
		}

		//TITLE
		.diary-title {
			width: 70%;
			height: 60px;
			padding: 0 0 0 0;
			display: table;
			vertical-align: top;
			font-weight: bold;

			a,
			span {
				display: table-cell;
				vertical-align: middle;
			}
		}

		//SHARE
		.add-share {
			position: absolute;
			top: 50%;
			right: 10px;
			width: 30px;
			display: inline-block;
			text-align: center;
			margin-top: -23px;

			a {
				display: inline-block;

				img {
					margin: 0;
				}
			}
		}
	}
}

// -----------------------------------//
// AUTO LIST
// -----------------------------------//

ul.auto-list {
	li {
		display: block;
		background-color: $auto-list-bg;
		border: 1px solid $auto-list-border-colour;
		padding: 10px 10px 10px 40px;
		background-position: 10px 12px;
		margin: 0 0 5px 0;
		background-repeat: no-repeat;
	}
}

//List view
ul.list-view-list {
	li {
		display: block;
		margin: 0 0 20px 0;
		padding: 10px 0 10px 55px;
	}
}

//Grid View
ul.grid-view-list {
	li {
		width: calc((100% / 3) - 90px);
		vertical-align: top;
		height: calc(150px - 60px);
		padding: 30px;
		margin: 0 25px 25px 0;
		display: inline-block;
		background-position: 0 0;
	}
}

img.ListingIcon {
	margin: 0 10px 0 0;
	float: left;
}

ul#DiaryListing li .add-share img {
	background: none;
	box-shadow: none;
	padding: 0;
	border-radius: 0;
}

// -----------------------------------//
// SLIDESHOW (GALLERIES)
// -----------------------------------//

.SScatHolder {
	width: 500px;
	height: 304px;
	color: #FFF;
	background-image: url(/admin/i/GalleryGroupPlaceholder.jpg);
	background-repeat: no-repeat;
	background-position: 0 0;
	display: block;
}

.SSHolder {
	width: 500px;
	height: 375px;
	color: #FFF;
	background-image: url(/admin/i/GalleryPlaceholder.jpg);
	background-repeat: no-repeat;
	background-position: 0 0;
}

.SSWrapper {
	border: 1px solid #666;
	margin: 5px 0 10px 0;
	padding: 0;
	height: 375px;
	vertical-align: middle;
	width: 500px;
	background-color: #FFF;
}

// Tidy up
#ss_gallery_holder {
	background: #fff;
	border: 1px solid #dcdcdc !important;
	margin-bottom: 40px !important;
}

#ss_gallery_holder h3 {
	margin: 0 0 20px 0 !important;
	padding: 10px 20px 0px 20px;
	font-size: 23px !important;
}


#SSlistblock {
	margin: 0;
	padding: 0;

	.singlegallery {
		width: 48%;
		margin: 0 0 30px 0;
		float: left;
		position: relative;
		max-height: 330px;
		overflow: hidden;

		&:nth-of-type(even) {
			float: right;
		}

		a.thumblink {
			display: inline-block;
			width: 100%;
			background: $gallery-bg;
			color: #fff;
			text-transform: uppercase;

			&:hover {
				.thumb-wrap {
					transition: (all 0.3s ease-in-out);
					opacity: .3;
				}
			}

			.thumb-wrap {
				transition: (all 0.3s ease-in-out);
				opacity: .5;
				min-height: 340px;
				background-size: cover;
				background-position: center center;

				img.thumb {
					width: 100%;
					height: auto;
				}
			}

			.summary {
				width: 100%;
				display: block;
				position: absolute;
				top: 50%;
				left: 0;
				text-align: center;
				margin-top: -20px;

				.title {
					margin: 5px 0;
					font-size: 18px;
					font-weight: bold;
				}

				.count {
					font-size: 12px;
					display: block;
					margin: 0 0 5px 0;
				}

				.description {
					font-size: 12px;
					display: block;
					margin: 0 0 5px 0;
				}
			}
		}
	}
}


// -----------------------------------//
// NEWS
// -----------------------------------//



ul#NewsListing {
	padding: 0;

	li {

		//News text box
		.text-box {
			display: inline-block;
			vertical-align: top;
			max-width: 100%;

			//Title
			span.title {
				display: block;
				font-weight: bold;
				text-transform: capitalize;
				display: block;
				font-size: 30px;
				margin: 20px auto 30px;
				line-height: 1.3;
				text-decoration: none;

				@media only screen and (max-width: 1450px) {
					font-size: 25px;
				}
			}

			//Page overview
			span.page-overview {
				display: block;
				font-size: 17px;
				overflow: hidden;
				display: -webkit-box;
				-webkit-line-clamp: 5;
				-webkit-box-orient: vertical;
				height: 130px;
			}

			//Date
			span.date {
				display: block;
				text-transform: uppercase;
				font-weight: bold;
				font-size: 14px;
				position: relative;
				padding: 0px;
				margin: 0 0 10px 0;
				letter-spacing: 0.1em;
			}
		}
	}
}


// ------------------------


// 04-05-2020 styles
ul#NewsListing li {
	border-bottom: 1px solid #d2d2d2;
	float: left;
	width: calc(50% - 20px);
	margin: 0 10px 20px 10px;
	box-sizing: border-box;
	height: 400px;
	overflow: hidden;
	box-shadow: inset 5px 0 0 #d2d2d2;
	transition: .3s;
	background: none;

	&:hover {
		box-shadow: inset 10px 0 0 #f9f9f9, inset 0 -800px 0 rgba(0, 0, 0, .1);
		transform: translateY(-5px);
		transition: .3s;
	}
}

// makes the entire element clickable
.clickable {
	position: absolute;
	height: 100%;
	width: 100%;
	display: block;
	padding: 30px 40px;
	box-sizing: border-box;
	left: 0;
	top: 0;
}

// photo box
ul#NewsListing li.background-box {
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center;
	position: relative;
	z-index: 0;

	//black overlay
	&:before {
		position: absolute;
		content: '';
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
		background: rgba(0, 0, 0, .6);
		z-index: -1;
	}

	//read more
	&:after {
		position: absolute;
		content: 'Read More';
		z-index: -1;
		color: #fff;
		font-weight: bold;
		font-size: 15px;
		right: 0px;
		bottom: 20px;
		transition: .3s;
		opacity: 0;
	}

	&:hover {
		&:after {
			opacity: 1;
			right: 50px;
		}
	}

	// text styles
	.text-box span.date {
		color: $box-light-date;
	}

	.text-box span.title {
		color: $box-light-title;
	}

	.text-box span.page-overview {
		color: #fff;
	}
}

// default box
ul#NewsListing li.background-box2 {
	position: relative;
	background: url(/i/design/news-list-default.jpg) no-repeat center center;
	background-size: cover;
	box-shadow: inset 5px 0 0 #000, 2px 0 2px rgba(0, 0, 0, .3);
	transition: .3s;
	z-index: 0;

	&:hover {
		box-shadow: inset 10px 0 0 #000, 2px 0 2px rgba(0, 0, 0, .3);
		transition: .3s;
	}

	// white overlay
	&:before {
		position: absolute;
		content: '';
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
		background: rgba(255, 255, 255, .9);
		z-index: -1;
	}

	// read more
	&:after {
		position: absolute;
		content: 'Read More';
		z-index: -1;
		color: #222;
		font-weight: bold;
		font-size: 15px;
		right: 0px;
		bottom: 20px;
		transition: .3s;
		opacity: 0;
	}

	&:hover {
		&:after {
			opacity: 1;
			right: 50px;
		}
	}

	// text styles
	.text-box span.date {
		color: $box-dark-date;
	}

	.text-box span.title {
		color: $box-dark-title;
	}

	.text-box span.page-overview {
		color: #444;
	}
}

// responsive
@media only screen and (max-width: 1200px) {
	ul#NewsListing li {
		display: block;
		clear: left;
		width: 100%;
		margin: 20px auto;
		height: 320px;

		.text-box span.page-overview {
			overflow: hidden;
			display: -webkit-box;
			-webkit-line-clamp: 4;
			-webkit-box-orient: vertical;
			height: 100px;
		}
	}
}

@media only screen and (max-width: 600px) {
	ul#NewsListing li {
		height: 400px;
		width: 100%;
		margin: 20px auto;
		border-radius: 0;
		padding: 20px;

		.text-box span.title {
			font-size: 18px;
		}
	}
}