/* ------------------------------------------------------------------------------------------

1. Create a free Vimeo account Sign up with EMAIL (not Google) 
username: support+SchoolName@greenhouse.email (eg support+Calderstones@greenhouse.email) 

2. Upload the video 
3. Grab the video ID

------------------------------------------------------------------------------------------ */



@media only screen and (min-width: 991px) {

    .body_3060 .backstretch {
        opacity: 0;
        animation: show-bs 5s 3s ease forwards 1;

        @keyframes show-bs {
            from {
                opacity: 0;
            }

            to {
                opacity: 1;
            }
        }
    }

    .video-background {
        opacity: 0;
        animation: show-vid 2s 1s ease forwards 1;

        @keyframes show-vid {
            from {
                opacity: 0;
            }

            to {
                opacity: 1;
            }
        }
    }
}

.video-background {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: -99;
}

.video-foreground,
.video-background iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    min-height: 750px;
    pointer-events: none;
    overflow: hidden;

    @media only screen and (max-width: 990px) {
        display: none;
    }
}

.video-background iframe {
    height: 130%;
    top: -15%;
    left: 0;
    right: 0;
    margin: 0 auto;
    object-fit: fill;
}