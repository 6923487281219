// -----------------------------------//
// ELEMENTS COLOURS
// -----------------------------------//
$slideOut-panel-bg: #FD834C;

$link-bg-colour: #FEB594;
$link-bg-colour-hover: #fff;
$links-colour: #fff;

$slide-click-colour: #fff;

$mobile-tab-color: #FD834C;

/* Full container */
#slideOut {
	position: fixed;
	width: auto;
	height: auto;
	min-height: 270px;
	top: 50%;
	left: -250px;
	padding: 0;
  z-index: 99999999;

	&:before { content: ''; position: absolute; right: -48px; top: 10px; width: 119px; height: 251px; background: url(/i/design/quick-links-before.png) no-repeat;}

}


/* Click to slide */
#slideClick {
	float: right;
	height: 100%;
	width: 70px;
	cursor: pointer;
	position: relative;

	span{
		width: 160px;
		height: 70px;
		position: absolute;
		top: 50%;
		left: 50%;
		font-weight: 700;
		font-size: 24px;
		color: $slide-click-colour;
		white-space: nowrap;
		transform: rotate(-90deg);
		margin-left: -73px;
		margin-top: -37px;
		&:before{
			content: "";
			width: 15px;
			height: 18px;
			background: url(/i/design/arrow-open-close.png) no-repeat center center;
			position: absolute;
			left: 48%;
			top: 77%;
			transform: rotate(90deg);
		}
	}
}

/* Content links */
#slideContent {
	width: auto;
	height: 100%;
	padding: 0;
	margin: 0;
	float: left;
	color: #EFEFEF;
	text-align: center;
	background: $slideOut-panel-bg;
	border-radius: 0 20px 20px 0;
}


/* Box with links  */
ul.quick-links{
	width: 240px;
	display: block;
	text-align: center;
	overflow: hidden;
	padding: 0 20px 0 0px;

	li{
		background: none;
		margin: 10px 0;
		padding: 0;
		display: block;


		a{
			background: $link-bg-colour;
			color: $links-colour;
			font-size: 20px;
			display: block;
			border-radius: 0 20px 20px 0;
			text-align: left;
			padding: 8px 10px 8px 20px;
			transition: 1s;
			&:hover{
				background-color: $link-bg-colour-hover;
				text-decoration: none;
				color: #FD834C;
				transition: 1s;
			}
		}
	}
}

@media only screen and (min-width: 750px) {

	#slideContent{ display: table !important; }
	ul.quick-links { vertical-align: middle; display: table-cell; }

}

/* Change the arrow  */
.open span::before{transform: rotate(-90deg) !important;}
.flip { display: none; }

@media only screen and (max-width: 750px) {
	#slideOut{
		position: relative;
		top: 44px;
		min-height: 0px;
		left: 0;
		padding: 0px 3% 0 2%;
		overflow: visible;
		height: auto !important;
		box-shadow: 0 0 0 1px rgba(0,0,0,0.08);
		border-radius: 0 0 10px 10px;
		margin: 0 5% !important;
		background: $mobile-tab-color;

		&:before { display: none;}
	}

	#slideClick{
		display: none;
		span{
			transform: rotate(0);
			position: absolute;
			top: 0;
			left: 0;
			width: auto;
			height: auto;
			margin-left: 0;
			margin-top: 0;
			position: relative;
		}
	}

	#slideContent{
		float: none;
	}

	ul.quick-links{
		width: 100%;
		li{
			a{
				padding: 6px 0 6px 0;
				text-align: center;
				background-position: 8% 10px;
			}
		}
	}


	.flip{
		z-index: 800;
		margin: 0;
		padding:0 0 5px 0;
		cursor: pointer;
		font-size: 16px;
		color: #fff;
		display: block;

		img{display: block; margin: 0 auto; transform: rotate(90deg);}
	}

	.open img{transform: rotate(-90deg);}
}
